/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import get from 'lodash/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import reverse from 'lodash/reverse';
import unionBy from 'lodash/unionBy';
import toString from 'lodash/toString';
import { useSelector } from 'react-redux';
import useInterval from './useInterval';
import { alert } from '../../utils';
import useLoading from '../../hooks/loading';
import {
  getMessages,
  getMessageChannel,
  createMessage,
  uploadFile,
} from '../../service';

import Style from './style';

const dateFormat = 'DD-MM-YYYY';
const Message = () => {
  const loading = useLoading();
  const inputRef = React.useRef(null);
  const user = useSelector(({ user }) => get(user, 'authUser'));

  const [height, setHeight] = React.useState(0); // default Height, detect on server.
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [media, setMedia] = React.useState({});
  const [channe, setChanne] = React.useState({});
  const [state, setState] = React.useState({
    messages: [],
    current: 0,
    total: 10,
    limit: 10,
  });

  const handleResize = () => {
    setHeight(window.innerHeight - 140);
  };

  const handleLoadNewMessage = async () => {
    try {
      const result = await getMessages(user.id, { limit: state.limit, offset: 0 });
      const values = get(result, 'data.data', []);
      setState({
        ...state,
        messages: [
          ...values.map((obj) => {
            return {
              ...obj,
              date: moment(obj.createdAt).format(dateFormat),
            };
          }),
          ...state.messages,
        ],
        total: get(result, 'data.meta.total', 10),
      });
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useInterval(async () => {
    handleLoadNewMessage();
  }, 5000);


  const handleLoadData = async () => {
    try {
      const result = await getMessages(user.id, { limit: state.limit, offset: state.current * state.limit });
      const values = get(result, 'data.data', []);
      setState({
        ...state,
        messages: [
          ...values.map((obj) => {
            return {
              ...obj,
              date: moment(obj.createdAt).format(dateFormat),
            };
          }),
          ...state.messages,
        ],
        current: state.current + 1,
        total: get(result, 'data.meta.total', 10),
      });
      console.log(result);
    } catch (e) {
      alert.error('Syatem error');
      console.error(e);
    }
  };

  const initPage = async () => {
    try {
      loading.open();
      const result = await getMessageChannel(user.id);
      setChanne(result.data);
      handleLoadData();
    } catch (e) {
      alert.error('Syatem error');
      console.error(e);
    }

    loading.close();
  };

  React.useEffect(() => {
    if (user.id) {
      handleResize();
      initPage();
    }
  }, [user.id]); // eslint-disable-line

  const handleSendMessage = async () => {
    if (inputRef.current) {
      const value = inputRef.current.value;
      if (value !== '') {
        try {
          const result = await createMessage({
            channelId: channe.id,
            message: value,
            type: 'text',
            createdBy: user.id,
          });
          setState({
            ...state,
            messages: [
              ...state.messages,
              ...result.data.map((obj) => {
                return {
                  ...obj,
                  date: moment(obj.createdAt).format(dateFormat),
                };
              }),
            ],
          });
          inputRef.current.value = '';
        } catch (e) {
          alert.error('Syatem error');
          console.error(e);
        }
      }
    }
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    try {
      const media = await uploadFile(file);
      if (media.data.url) {
        const result = await createMessage({
          channelId: channe.id,
          message: media.data.url,
          type: 'image',
          createdBy: user.id,
        });
        setState({
          ...state,
          messages: [
            ...state.messages,
            ...result.data.map((obj) => {
              return {
                ...obj,
                date: moment(obj.createdAt).format(dateFormat),
              };
            }),
          ],
        });
      }
    } catch (err) {
      alert.error('Syatem error');
      console.error('upload error', err);
    }
  };

  const handleUploadVideo = async (e) => {
    const file = e.target.files[0];
    try {
      const media = await uploadFile(file);
      if (media.data.url) {
        const result = await createMessage({
          channelId: channe.id,
          message: media.data.url,
          type: 'video',
          createdBy: user.id,
        });
        setState({
          ...state,
          messages: [
            ...state.messages,
            ...result.data.map((obj) => {
              return {
                ...obj,
                date: moment(obj.createdAt).format(dateFormat),
              };
            }),
          ],
        });
      }
    } catch (err) {
      alert.error('Syatem error');
      console.error('upload error', err);
    }
  };

  const handleOpenVideo = (message) => {
    setIsModalOpen(true);
    setMedia(message);
  };

  const renderMessage = (message = {}) => {
    switch (message.type) {
      case 'image': {
        return (
          <div className="message image">
            <img src={get(message, 'message', '')} alt="" />
          </div>
        );
      }
      case 'video': {
        return (
          <div className="message video">
            <button type="button" onClick={() => handleOpenVideo(message)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
              >
                <path
                  d="M20.5 40C31.2696 40 40 31.2696 40 20.5C40 9.73045 31.2696 1 20.5 1C9.73045 1 1 9.73045 1 20.5C1 31.2696 9.73045 40 20.5 40Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.6001 12.7001L28.3001 20.5001L16.6001 28.3001V12.7001Z"
                  fill="white"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <video preload="metadata">
              <track kind="captions" />
              <source
                src={`${get(message, 'message', '')}#t=5.0`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      }
      default: {
        return <p className="message text">{get(message, 'message', '')}</p>;
      }
    }
  };

  const itemMessage = (message) => {
    const isMe = toString(get(message, 'createdBy')) === toString(user.id);

    if (message.type === 'session') {
      return (
        <div className="wrap-session">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M22.5 11.08V12C22.4988 14.1564 21.8005 16.2547 20.5093 17.9818C19.2182 19.709 17.4033 20.9725 15.3354 21.5839C13.2674 22.1953 11.0573 22.1219 9.03447 21.3746C7.01168 20.6273 5.28465 19.2461 4.11096 17.4371C2.93727 15.628 2.37979 13.4881 2.52168 11.3363C2.66356 9.18455 3.49721 7.13631 4.89828 5.49706C6.29935 3.85781 8.19279 2.71537 10.2962 2.24013C12.3996 1.7649 14.6003 1.98232 16.57 2.85999"
                stroke="#04AA28"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.5 4L12.5 14.01L9.5 11.01"
                stroke="#04AA28"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>{get(message, 'message')}</span>
          </div>
        </div>
      );
    }

    return (
      <div key={message.id} className="wrap-chat">
        <div className={`content ${isMe ? 'me' : ''}`}>
          <div className="wrap-message">
            <div>
              {renderMessage(message)}
              <div className="time">
                {moment(get(message, 'createdAt', '')).format('HH:mm')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const sortMessage = orderBy(
    unionBy(state.messages, 'id'),
    'createdAt',
    'asc'
  );
  let items = [];

  forEach(groupBy(sortMessage, 'date'), (values, date) => {
    let dateMessage = '';
    if (date === moment().format(dateFormat)) {
      dateMessage = 'Today';
    } else {
      dateMessage = moment(date).format();
    }

    items = [
      ...items,
      <div className="wrap-date" key={date}>
        <span>{dateMessage}</span>
      </div>,
      ...map(orderBy(values, 'createdAt', 'asc'), (message) => {
        return itemMessage(message);
      }),
    ];
  });

  const { current, total, limit } = state;

  return (
    <Style>
      <div className="header">
        <button type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="none"
          >
            <path
              d="M10.4545 18.5714L1 10L10.4545 1.42859"
              stroke="#393F4D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <span>แจ้งเหตุ</span>
      </div>
      <div className="check-box">
        <div
          id="support-message"
          className="not-draggable"
          style={{
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
            height,
          }}
        >
          <InfiniteScroll
            dataLength={items.length}
            next={handleLoadData}
            hasMore={current * limit < total}
            loader={<div className="loading-message">Loading</div>}
            style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            inverse
            scrollableTarget="support-message"
          >
            {reverse(items)}
          </InfiniteScroll>
        </div>
        <div className="wrap-input">
          <div className="wrap-file">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path d="M8 8V14H6V8H0V6H6V0H8V6H14V8H8Z" fill="#393F4D" />
              </svg>
            </span>
            <input
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              onChange={handleUploadVideo}
            />
          </div>
          <div className="wrap-file">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                  stroke="#393F4D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
                  stroke="#393F4D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 15L16 10L5 21"
                  stroke="#393F4D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <input type="file" accept="image/*" onChange={handleUploadImage} />
          </div>
          <input
            placeholder="Enter message .."
            ref={inputRef}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                handleSendMessage();
              }
            }}
          />
          <button
            type="button"
            className="button-send"
            onClick={() => handleSendMessage()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14.1754 23.1429C14.5583 23.1429 14.8881 22.9904 15.1647 22.6854C15.4412 22.3805 15.6788 21.9727 15.8774 21.4621L22.845 3.26124C22.9372 3.01303 23.0081 2.78255 23.0577 2.5698C23.1145 2.35704 23.1428 2.15848 23.1428 1.97409C23.1428 1.6266 23.0435 1.35357 22.845 1.155C22.6464 0.956431 22.3734 0.857147 22.0259 0.857147C21.8486 0.857147 21.65 0.885514 21.4302 0.942248C21.2174 0.99189 20.9869 1.06281 20.7387 1.155L2.44211 8.16516C1.99534 8.33536 1.61948 8.55875 1.31453 8.83533C1.00959 9.1119 0.857117 9.44167 0.857117 9.82462C0.857117 10.3139 1.02377 10.6685 1.35708 10.8884C1.69039 11.1011 2.11235 11.2855 2.62295 11.4415L8.36724 13.1861C8.70764 13.2925 8.98777 13.335 9.20761 13.3137C9.42745 13.2854 9.65439 13.1648 9.88842 12.9521L21.5578 2.04856C21.6217 1.98473 21.689 1.95636 21.7599 1.96345C21.838 1.96345 21.9053 1.98828 21.9621 2.03792C22.0188 2.08756 22.0472 2.15139 22.0472 2.22939C22.0472 2.3074 22.0152 2.37832 21.9514 2.44215L11.0905 14.1541C10.8848 14.374 10.7678 14.5938 10.7394 14.8136C10.7111 15.0264 10.7465 15.3101 10.8458 15.6646L12.5372 21.2813C12.6932 21.8132 12.8811 22.2564 13.101 22.611C13.3208 22.9656 13.6789 23.1429 14.1754 23.1429Z"
                fill="#EE6538"
              />
            </svg>
          </button>
        </div>
      </div>
      {isModalOpen && (
        <div className="view-video">
          <div
            className="overlay"
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
          <div className="button-close">
            <button
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path
                  fill="#fff"
                  d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
                />
              </svg>
            </button>
          </div>
          <div className="wrap-video">
            <video preload="metadata" controls>
              <track kind="captions" />
              <source src={`${get(media, 'message', '')}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </Style>
  );
};

Message.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

Message.defaultProps = {};

export default Message;
