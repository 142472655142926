import styled from '@emotion/styled';

export default styled.div`
  label: page-message;
  > .header {
    display: flex;
    align-items: center;
    height: 68px;
    padding: 16px;
    background: #fff;
    > button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
    }
    > span {
      flex: 1 1 auto;
      display: block;
      text-align: center;
      font-size: 20px;
      line-height: 28px;
    }
  }
  .loading-message {
    text-align: center;
  }
  .check-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    background: #eeeeee;
    > .wrap-input {
      display: flex;
      align-items: center;
      box-shadow: 0px 1px 10px rgb(104 120 171 / 12%);
      padding: 12px 16px;
      background: #fff;
      .wrap-file {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
        }
        > input {
          position: absolute;
          z-index: 2;
          display: block;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          cursor: pointer;
          opacity: 0;
          margin: 0 10px;
        }
      }
      > input {
        width: 100%;
        display: block;
        height: 40px;
        padding: 12px;
        border: none;
        outline: none;
        background: #f7f7fc;
      }
      .button-send {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        height: 40px;
        background: none;
        border: none;
        outline: none;
      }
    }
    .wrap-date {
      margin: 16px 0;
      display: flex;
      justify-content: center;
      > span {
        font-size: 12px;
        color: var(--gray-600);
        padding: 8px 20px;
      }
    }
    .wrap-session {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
      > div {
        background: #cbffd6;
        padding: 4px 16px;
        color: #04aa28;
        display: flex;
        align-items: center;
        font-weight: 300;
        border-radius: 30px;
        > span {
          margin-left: 8px;
          font-size: 14px;
        }
      }
    }
    .wrap-chat {
      > .name {
        color: var(--gray-700);
        font-size: 14px;
        white-space: nowrap;
        margin-bottom: 4px;
      }
      .content {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 8px;
        &.me {
          justify-content: flex-end;
          .wrap-message {
            order: 1;
            background: #ee6538;
            color: #fff;
            border-radius: 16px 16px 0px 16px;
            .time {
              text-align: right;
              color: #fff;
            }
            .message {
              order: 2;
              margin-right: 0;
              margin-left: 8px;
            }
          }
        }
        .wrap-message {
          order: 2;
          color: #393f4d;
          background: #fff;
          padding: 10px;
          border-radius: 16px 16px 16px 0px;
          > div {
            display: flex;
            flex-direction: column;
          }
          .time {
            text-align: left;
            order: 2;
            color: #adb5bd;
            font-size: 10px;
          }
          .message {
            margin-bottom: 0;
            border-radius: 8px;
            order: 1;
            margin-right: 8px;
            font-size: 14px;
            font-weight: 300;
            white-space: pre-wrap;
            &.image {
              padding: 0;
              img {
                max-width: 300px;
                max-height: 300px;
              }
            }
            &.video {
              position: relative;
              > video {
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                max-width: 385px;
                max-height: 230px;
                @media (max-width: 512px) {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              > button {
                z-index: 2;
                position: absolute;
                background: transparent;
                border: none;
                outline: none;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    #support-message {
      padding: 8px 16px;
      width: 100%;
    }
  }
  .view-video {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 512px) {
      padding: 20px;
    }
    > .overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: #000;
      opacity: 0.5;
    }
    > .button-close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;
      button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        color: #fff;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    > .wrap-video {
      position: relative;
      z-index: 2;
      video {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
`;
